<template>
    <div class="task-card-done-list" :class="{ scroll: tasks.length > 5 }">
        <div
            class="done-task"
            :class="{ 'hide-border': index === tasks.length - 1, 'pointer-cursor': icon === 'waiting' }"
            v-for="(t, index) in tasks"
            :key="index"
            @click="editTask(t._id)"
        >
            <button :class="[icon]">
                <v-icon v-show="icon === 'success'"> mdi-checkbox-marked-circle </v-icon>
                <v-icon v-show="icon === 'failure'"> mdi-alert-circle </v-icon>
                <v-icon v-show="icon === 'waiting'"> mdi-clock-time-five </v-icon>
            </button>

            <p>{{ t.title }}</p>
        </div>

        <task-modal v-if="openModal" :task="task" @close-modal="openModal = false" />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { mainStore } from '../../../../store'

import TaskModal from '../../../modals/tasks/TaskModal.vue'

const store = mainStore()

const props = defineProps({
    icon: {
        type: String,
        default: 'success',
    },
    tasks: {
        type: Array,
        default: [],
    },
})

const openModal = ref(false)
const task = ref({})

function editTask(taskId) {
    task.value = store.findById('tasks', taskId)
    if (props.icon === 'waiting') openModal.value = true
}
</script>

<style lang="scss" scoped>
.task-card-done-list {
    height: 80%;
    width: 100%;

    &.scroll {
        overflow-y: scroll;
    }

    .done-task {
        height: 16%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $lighter-grey;

        &.pointer-cursor {
            cursor: pointer;
        }

        p {
            font-size: 14px;
            color: $black;
            font-weight: 600;
        }

        .success,
        .failure,
        .waiting {
            font-size: 24px;
            border: none;
            box-shadow: none;
        }

        .success {
            color: #2dc785;
        }

        .failure {
            color: $hint-red;
        }

        .waiting {
            color: $gold;
        }

        &.hide-border {
            border-bottom: unset;
        }
    }
}
</style>
