import moment from 'moment'

export default {
    weeklySpending() {
        const startDate = moment().startOf('week')
        const endDate = moment().endOf('week')

        const data = [
            ...this.transactions,
            ...this.invoicesWithoutFiltering.reduce((prevValue, invoice) => {
                prevValue.push(...invoice.items)
                return prevValue
            }, []),
        ]

        return data
            .filter((transaction) => {
                const date = moment(transaction.date)

                return (
                    date.isSameOrAfter(startDate) &&
                    date.isSameOrBefore(endDate) &&
                    transaction.transactionType === 'expenses' &&
                    transaction.paid &&
                    this.checkCategorySortType(transaction.category._id)
                )
            })
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)
    },

    monthSpending() {
        const data = [
            ...this.transactions,
            ...this.invoicesWithoutFiltering.reduce((prevValue, invoice) => {
                prevValue.push(...invoice.items)
                return prevValue
            }, []),
        ]

        return data
            .filter(
                (transaction) =>
                    transaction.transactionType === 'expenses' &&
                    transaction.paid &&
                    this.checkCategorySortType(transaction.category._id),
            )
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)
    },

    budgetBalance() {
        return (type) => {
            if (this.budgets._id) {
                switch (type) {
                    case 'essential':
                        return this.budgets.essentialCategories.reduce((prevValue, bCategory) => {
                            return prevValue + this.balancesPerCategory({ category: bCategory.category._id })
                        }, 0)
                    case 'noessential':
                        return this.budgets.noessentialCategories.reduce((prevValue, bCategory) => {
                            return prevValue + this.balancesPerCategory({ category: bCategory.category._id })
                        }, 0)
                    default:
                        return this.balancesPerCategory()
                }
            }

            return 0
        }
    },

    /**
     * Retorna as receitas previstas no planejamento atual. Caso não tenha valor pode ser utilizado o valor do diagnóstico
     * @returns {number}
     */
    planningRevenuesValue() {
        return (otherPlanning) => {
            const planning = otherPlanning || this.budgets
            return planning.revenuesValue || 0
        }
    },

    /**
     * Retorna as despesas prevista do planejamento atual
     * @returns {number}
     */
    planningExpensesValue() {
        return (otherPlanning) => {
            const planning = otherPlanning || this.budgets

            const _planning = planning._id
                ? planning
                : {
                      essentialValue: 0,
                      noessentialValue: 0,
                      investimentValue: 0,
                      debtsValue: 0,
                  }

            return (
                _planning.essentialValue +
                _planning.noessentialValue +
                _planning.investimentValue +
                _planning.debtsValue
            )
        }
    },

    /**
     * Balanço entre receitas e despesas previstas no planejamento
     * @returns {number}
     */
    planningBalance() {
        return this.planningRevenuesValue() - this.planningExpensesValue()
    },

    /**
     * Retorna as cores do gráfico de donut do planejamento
     *
     * @returns {string[]}
     */
    planningChartColors() {
        return ['#f66668', '#f23e43', '#8c0b11', '#63b9f2', '#9FB6CD']
    },

    /**
     * Define os séries do gráfico de donut do planejamento
     * @returns {number[]}
     */
    planningChartSeries() {
        if (!this.budgets._id) return [1]

        return ['essentialIncome', 'noessentialIncome', 'debtsIncome', 'investimentIncome', 'remainIncome'].map(
            (key) => {
                return this.planningIncomes()[key]
            },
        )
    },

    /**
     * Valores na moeda seleciona, reservados para cada categoria
     * @returns {{ essentialIncome: number, noessentialIncome: number, debtsIncome: number, investimentIncome: number, remainIncome: number }}
     */
    planningIncomes() {
        const incomes = {
            essentialIncome: 0,
            noessentialIncome: 0,
            debtsIncome: 0,
            investimentIncome: 0,
            remainIncome: 0,
        }

        return (otherPlanning) => {
            const planning = otherPlanning || this.budgets

            if (!planning._id) return incomes

            const revenues = this.planningRevenuesValue(planning) || this.planningExpensesValue(planning)

            incomes.essentialIncome = planning.essentialValue
            incomes.noessentialIncome = planning.noessentialValue
            incomes.debtsIncome = planning.debtsValue
            incomes.investimentIncome = planning.investimentValue
            incomes.remainIncome =
                revenues -
                incomes.essentialIncome -
                incomes.noessentialIncome -
                incomes.debtsIncome -
                incomes.investimentIncome

            if (incomes.remainIncome < 0) incomes.remainIncome = 0

            return incomes
        }
    },
}
