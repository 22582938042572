import moment from 'moment'

export default {
    /** BALANÇO ESPERADO DO MÊS SELECIONADO POR GRUPO */
    balanceMonthExpectedPerType() {
        const store = this

        return (group, payment, userId, isPlanning) => {
            const getter = payment ? 'filteredTransactions' : 'transactionsWithoutMovBanc'

            let sum = store[getter](group).reduce((prevValue, transaction) => {
                let valid = true

                if (payment) valid = transaction.payment._id === payment
                if (userId) valid = valid && transaction.user._id === userId

                return valid ? prevValue + transaction.value : prevValue
            }, 0)

            return sum
        }
    },

    // PREVISÃO DO SALDO DO MÊS ATUAL
    /**
     *
     * @param {*} isPlanning - Verifica se o saldo é do planejamento que pode considerar as receitas inseridas no diagnóstico
     * @returns
     */
    balanceTotalMonth(isPlanning) {
        const store = this

        return (userId) => {
            return (
                store.balanceMonthExpectedPerType('revenues', null, userId, isPlanning) +
                store.balanceMonthExpectedPerType('expenses', null, userId)
            )
        }
    },

    // BALANÇO TOTAL DO MÊS PARA TRANSAÇÕES CONSOLIDADAS OU NÃO CONSOLIDADAS
    balanceMonthPerType() {
        return (group, paid, payment, user, otherTransactions) => {
            let transactions = payment
                ? this.filteredTransactions(group, undefined, user, otherTransactions)
                : this.transactionsWithoutMovBanc(group, user, otherTransactions)

            transactions = transactions.filter((transaction) => {
                let valid = true
                const _paid = !!transaction.paid

                if (payment) valid = transaction.payment._id === payment

                return valid && _paid == paid
            })

            return transactions.reduce(function (prevValue, transaction) {
                return prevValue + transaction.value
            }, 0)
        }
    },

    // PREVISÃO DO SALDO DO MÊS ANTERIOR
    balancePreviousMonths(state) {
        return state.balances.currentBalance - this.balanceActualMonth(null)
    },

    // SALDO ATUALIZADO DO MÊS ATUAL
    balanceActualMonth() {
        const store = this

        return (payment, userId) => {
            if (payment) {
                let paymentId = payment._id || payment
                const creditCard = (store.findById('payments', paymentId) || {}).type === 'credit'

                if (creditCard) {
                    return store.getInvoiceValue(payment)
                }

                return (
                    store.balanceMonthPerType('revenues', true, payment) +
                    store.balanceMonthPerType('expenses', true, payment)
                )
            }
            return (
                store.balanceMonthPerType('revenues', true, undefined, userId) +
                store.balanceMonthPerType('expenses', true, undefined, userId)
            )
        }
    },

    /**
     * SOMA DE TODAS AS DESPESAS DE DETERMINADA CATEGORIA PELO SEU TIPO
     * @returns {number}
     */
    balancesPerCategoryByType() {
        const store = this

        return (categoryType) => {
            let transactionsList = store.filteredTransactions('expenses').filter((transaction) => {
                let defaultCategory = store.getDefaultCategoryById(transaction.category._id)
                return defaultCategory.type === categoryType
            })

            transactionsList.push(...store.getItemsByCategoryType(categoryType))

            return transactionsList.reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)
        }
    },

    /**
     * SOMA DE TODAS AS TRANSAÇÕES COM DETERMINADA CATEGORIA
     * @returns {number}
     */
    balancesPerCategory() {
        return ({ category, group = 'expenses', otherTransactions, otherInvoices }) => {
            if (category) {
                let transactionsList = this.filterTransactionsPerCategory({
                    group,
                    category,
                    isBudget: true,
                    otherTransactions,
                    otherInvoices,
                })

                return transactionsList.reduce((prevValue, transaction) => {
                    return prevValue + transaction.value
                }, 0)
            }

            return 0
        }
    },

    /**
     * SALDO DE FORMAS DE PAGAMENTO POR TIPO
     * @returns {number}
     */
    balancePaymentsPerType() {
        const store = this

        return (type, subType) => {
            return store.filteredPayments(type).reduce(function (prevValue, payment) {
                if (subType) {
                    return payment.accountType === subType ? prevValue + payment.value : prevValue
                } else if (type === 'credit') {
                    if (!payment.externalId) {
                        const invoice = store.findTransactionInvoice(payment._id)
                        return prevValue + (invoice ? invoice.value : 0)
                    }

                    return prevValue + store.getInvoiceValue(payment._id)
                } else {
                    return prevValue + payment.value
                }
            }, 0)
        }
    },

    // BALANÇO DE TODAS AS FORMAS DE PAGAMENTO
    balancePayments() {
        const notCredit = this.payments.reduce(function (prevValue, payment) {
            if (payment.type === 'credit') {
                return prevValue
            }
            return prevValue + payment.value
        }, 0)

        const credit = this.transactions
            .filter((t) => t.invoiceReferenceId !== undefined && !t.externalId)
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)

        return notCredit
    },

    /* BALANÇOS DOS INVESTIMENTOS (SÓ POUPANÇA AINDA) */
    balanceInvestments() {
        const savingIds = this.payments.map((payment) => {
            if (payment.accountType === 'Poupança') return payment._id
        })

        const balanceSavings = this.transactions
            .filter((transaction) => savingIds.includes(transaction.payment._id) && !transaction.paid)
            .reduce((prevValue, transaction) => {
                return prevValue + transaction.value
            }, 0)

        return balanceSavings + this.balancePaymentsPerType('debit', 'Poupança')
    },

    /* BALANÇO DO CALENDÁRIO DE PAGAMENTOS */
    balanceCalendar() {
        return (filter = {}, paid) => {
            let linkedtransactionBalance = this.filterBy('linkedtransactions', filter).reduce(
                (prevValue, transaction) => {
                    const isPaid = () => {
                        if (!transaction.lastPaymentDate) return false
                        return moment(transaction.lastPaymentDate).isSameOrAfter(moment(), 'M')
                    }

                    if (paid !== undefined) {
                        if ((paid && isPaid()) || (!paid && !isPaid())) return prevValue + transaction.value
                        return prevValue
                    }

                    return prevValue + transaction.value
                },
                0,
            )

            let transactions = []
            const _filter = {}

            Object.entries(filter).forEach((entry) => {
                const [attr, nestedAttr] = entry[0].split('.')

                if (nestedAttr) _filter[nestedAttr] = entry[1]
                else _filter[attr] = entry[1]
            })

            if (paid !== undefined) _filter.paid = paid

            transactions.push(...this.filterBy('transactions', { recurrent: true, ..._filter }))
            transactions.push(...this.filterBy('transactions', { invoiceReferenceId: { $exists: true }, ..._filter }))

            return transactions.reduce((prevValue, transaction) => {
                if (transaction.user._id !== this.user._id) return prevValue
                return prevValue + transaction.value
            }, linkedtransactionBalance)
        }
    },

    /**
     * Balanços relacionados aos status do mês na tela de relatório
     * @return {{revenues: { planned: number, real: number }, essential: { planned: number, real: number }, noessential: { planned: number, real: number }, debts: { planned: number, real: number }, investiment: { planned: number, real: number }, total:  { planned: number, real: number }, balance:  { planned: number, real: number }}}
     */
    balancePerCategoryGroup() {
        return (transactions, planning, invoices, family) => {
            const userId = family ? false : this.mainUserId

            const categoriesPerGroup = this.newGroupedCategories(undefined, true, userId).map((categories) => ({
                data: categories.data.map((c) => c._id),
            }))

            if (!invoices) invoices = this.invoicesWithoutFiltering

            const balances = {
                revenues: {
                    real: this.balanceMonthPerType('revenues', true, undefined, userId, transactions),
                    planned: this.planningRevenuesValue(planning),
                },
                essential: {
                    real: this.balancesPerCategory({
                        category: categoriesPerGroup[0].data,
                        otherTransactions: transactions,
                        otherInvoices: invoices,
                    }),
                    planned: this.planningIncomes(planning).essentialIncome,
                },
                noessential: {
                    real: this.balancesPerCategory({
                        category: categoriesPerGroup[1].data,
                        otherTransactions: transactions,
                        otherInvoices: invoices,
                    }),
                    planned: this.planningIncomes(planning).noessentialIncome,
                },
                debts: {
                    real: this.balancesPerCategory({
                        category: categoriesPerGroup[2].data,
                        otherTransactions: transactions,
                        otherInvoices: invoices,
                    }),
                    planned: this.planningIncomes(planning).debtsIncome,
                },
                investiment: {
                    real: this.balancesPerCategory({
                        category: categoriesPerGroup[3].data,
                        otherTransactions: transactions,
                        otherInvoices: invoices,
                    }),
                    planned: this.planningIncomes(planning).investimentIncome,
                },
            }

            return {
                ...balances,
                total: {
                    real:
                        balances.essential.real +
                        balances.noessential.real +
                        balances.debts.real +
                        balances.investiment.real,
                    planned:
                        balances.essential.planned +
                        balances.noessential.planned +
                        balances.debts.planned +
                        balances.investiment.planned,
                },
                balance: {
                    real:
                        balances.essential.real +
                        balances.noessential.real +
                        balances.debts.real +
                        balances.investiment.real +
                        balances.revenues.real,
                    planned:
                        balances.revenues.planned -
                        balances.essential.planned -
                        balances.noessential.planned -
                        balances.debts.planned -
                        balances.investiment.planned,
                },
            }
        }
    },
}
